import React, { FunctionComponent } from 'react'

import PuxPicture, { PuxPictureType } from '../content/mediaImage/PuxPicture'

export interface EasySoftwareIconBarItemType {
  displayText: string
  iconBarItemImage: PuxPictureType
}

const rootClass = `iconbar-item`

const EasySoftwareIconBarItem: FunctionComponent<EasySoftwareIconBarItemType> = (
  props
) => {
  return (
    <div className={rootClass}>
      <PuxPicture {...props.iconBarItemImage} height={32} width={32} />
      <div className={`${rootClass}-title`}>{props.displayText}</div>
    </div>
  )
}

export default EasySoftwareIconBarItem
