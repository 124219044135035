import './EasySoftwareIconBar.scss'

import React, { FunctionComponent } from 'react'

import EasySoftwareIconBarItem, {
  EasySoftwareIconBarItemType,
} from './EasySoftwareIconBarItem'

export interface EasySoftwareIconBarType {
  displayText: string
  iconBarIconList: {
    contentItems: [EasySoftwareIconBarItemType]
  }
}

const rootClass = `iconbar`

const EasySoftwareIconBar: FunctionComponent<EasySoftwareIconBarType> = (
  props
) => {
  return (
    <div className={rootClass}>
      <div className={`${rootClass}-items`}>
        {props.iconBarIconList &&
          props.iconBarIconList.contentItems &&
          props.iconBarIconList.contentItems.map((item, idx) => (
            <EasySoftwareIconBarItem {...item} key={idx} />
          ))}
      </div>
    </div>
  )
}

export default EasySoftwareIconBar
